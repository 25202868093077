

















import { mapState, mapGetters } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'

import { AsyncStatusKey } from '@/vuex/asyncStatus'
import UserEvent from '@/models/UserEvent'

import PageTitle from '@/components/PageTitle.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: { PageTitle, LoadingSpinner },
  computed: {
    ...mapState('userEvents', ['userEvents']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class OrdersView extends Vue {
  userEvents!: UserEvent[] | null
  isInProgress!: (key: AsyncStatusKey) => boolean

  get eventsHaveBeenLoaded(): boolean {
    return !!this.userEvents
  }

  get hasEvents(): boolean {
    return !!(this.userEvents && this.userEvents.length)
  }

  get showSpinner(): boolean {
    return this.isInProgress('userEvents/load') && !this.eventsHaveBeenLoaded
  }

  acknowledge(id: string): void {
    this.$store.dispatch('userEvents/acknowledge', {
      id,
    })
  }

  created(): void {
    this.$store.dispatch('userEvents/load')
  }
}
